import { handleActions } from 'redux-actions';
import {
  dropDiagramEvent,
  dropDiagramEvents,
  dropEvent,
  dropSwState,
  setDiagramEvent,
  setEvent,
  setSwState,
} from './actions';

const initialState = {
  snackbar: { type: '', message: '', desc: '', display: false },
  diagramEvents: [],
  swState: { type: '', online: true },
};

const eventReducer = handleActions(
  {
    [setEvent]: (state, { payload }) => {
      if (state.swState.online)
        return {
          ...state,
          snackbar: {
            type: payload.snackbar.type,
            message: payload.snackbar.message,
            desc: payload.snackbar.desc || '',
            display: payload.snackbar.display,
          },
        };
      return state;
    },
    [dropEvent]: (state) => ({
      ...state,
      snackbar: {
        ...initialState.snackbar,
      },
    }),
    [setDiagramEvent]: (state, { payload }) => ({
      ...state,
      diagramEvents: [...state.diagramEvents, payload],
    }),
    [dropDiagramEvents]: (state) => ({
      ...state,
      diagramEvents: initialState.diagramEvents,
    }),
    [dropDiagramEvent]: (state, { payload }) => {
      const diagramEvents = state.diagramEvents.filter(
        ({ data }) =>
          (data.id && data.id !== payload) ||
          (data &&
            data.newNodeDB &&
            data.newNodeDB.render &&
            data.newNodeDB.render.id &&
            data.newNodeDB.render.id !== payload),
      );

      return {
        ...state,
        diagramEvents,
      };
    },
    [setSwState]: (state, { payload }) => ({
      ...state,
      swState: payload,
    }),
    [dropSwState]: (state) => ({
      ...state,
      swState: initialState.swState,
    }),
  },
  initialState,
);

export default eventReducer;
