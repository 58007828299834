import React, { useEffect, useMemo, useState } from 'react';
import { AccordionDetails, Typography } from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';
import { BulkImportItem } from '../../../../../redux/services/visuals/api';
import { capitalizeFirstLetter } from '../../../../../utils/stringUtils';
import { Count, Group, GroupHead, GroupsContainer, ImportItem } from './styles';
import DialogComponent from '../../../../Dialogs/DialogComponent';

interface Props {
  result: (BulkImportItem & { name: string })[];
  onClose: () => void;
}

const getMessage = (item: BulkImportItem) => {
  if (item.code === 200) return 'Imported successfully';
  if (item.code === 500) return 'Something went wrong. Not imported';
  return item.detail;
};

const getResponseGroups = (items: BulkImportItem[]) => {
  const groups = [];
  if (items.some(({ code }) => code === 200))
    groups.push('Imported successfully');
  if (items.some(({ code }) => code === 500)) groups.push('Errors');
  const messages = items
    .filter(({ code }) => code !== 200 && code !== 500)
    .map(({ detail }) => detail);
  groups.push(...[...new Set(messages)]);
  return groups;
};

const ImportResultsComponent = (props: Props) => {
  const { result, onClose } = props;
  const [expanded, setExpanded] = useState<string[]>([]);

  const groups = useMemo(() => getResponseGroups(result), [result]);

  useEffect(() => {
    if (!groups) return;
    setExpanded(groups);
  }, [groups]);

  const handleChangeGroupOpen = (group: string) => {
    setExpanded((prev) => {
      if (prev.indexOf(group) === -1) return [...prev, group];
      return prev.filter((groupName) => groupName !== group);
    });
  };

  return (
    <DialogComponent
      isOpened
      closeModal={onClose}
      title="Import results"
      isActionButtonDisplayed={false}
      cancelButtonTitle="Close"
      sx={{ zIndex: 1402 }}
    >
      <GroupsContainer>
        {groups.map((group) => {
          const idGroup = group.replace(/ /gm, '_');
          return (
            <Group
              key={group}
              expanded={expanded.includes(group)}
              onChange={() => handleChangeGroupOpen(group)}
              id={`ImportResult-${idGroup}-accordion`}
            >
              <GroupHead
                expandIcon={<ExpandMoreRounded />}
                aria-controls={`${group}-content`}
                id={`${idGroup}-header`}
              >
                <Typography
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {capitalizeFirstLetter(`${group}`)}
                </Typography>
                <Count>
                  {
                    result.filter((res) => {
                      if (group === 'Imported successfully' && res.code === 200)
                        return true;
                      if (group === 'Errors' && res.code === 500) return true;
                      if (group === getMessage(res)) return true;
                      return false;
                    }).length
                  }
                </Count>
              </GroupHead>
              <AccordionDetails id={`ImportResult-${idGroup}-accordionDetails`}>
                {result
                  .filter((item) => {
                    if (group === 'Imported successfully' && item.code === 200)
                      return true;
                    if (group === 'Errors' && item.code === 500) return true;
                    if (group === getMessage(item)) return true;
                    return false;
                  })
                  .map((item) => (
                    <ImportItem key={item.name}>
                      <Typography>{item.name}</Typography>
                      <Typography>{getMessage(item)}</Typography>
                    </ImportItem>
                  ))}
              </AccordionDetails>
            </Group>
          );
        })}
      </GroupsContainer>
    </DialogComponent>
  );
};

export default ImportResultsComponent;
