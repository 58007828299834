import React, { useEffect, useMemo, useState } from 'react';
import { Stack, Button } from '@mui/material';
import { XYPosition } from 'react-flow-renderer';
import { Actions } from '../styles';
import { useGetVisualsQuery } from '../../../../redux/services/visuals/api';
import { apiBaseUrlV1 } from '../../../../env';
import { useAppSelector } from '../../../../redux/store';
import { IAppState } from '../../../../typescript/interfaces/appstate.interface';
import EmptyVisualElement from '../EmptyVisualElement';
import ModalContentLeft from './ModalContentLeft';
import ModalContentRight from './ModalContentRight';

interface Props {
  isTablet: boolean;
  searchValue: string;
  isDrawerOpened: boolean;
  toggleDrawer: (val: boolean) => void;
  isListView: boolean;
  onClose: () => void;
  selectedVisuals: string[];
  pasteHandler: ({
    nodeId,
    position,
  }: {
    nodeId: string;
    position?: XYPosition;
  }) => void;
  setSelectedVisuals: React.Dispatch<React.SetStateAction<string[]>>;
  handleSearchInput: (ev: React.ChangeEvent<HTMLInputElement> | string) => void;
  toggleListView: (val: boolean) => void;
  handleInnerModalOpen: () => void;
}

const getVisualAssetUrl = (id, version) =>
  `${apiBaseUrlV1('structure/v1')}/visuals/${id}/asset/${version}`;

const ModalContent = (props: Props) => {
  const {
    isTablet,
    searchValue,
    isListView,
    isDrawerOpened,
    toggleDrawer,
    onClose,
    selectedVisuals,
    pasteHandler,
    setSelectedVisuals,
    handleInnerModalOpen,
    handleSearchInput,
    toggleListView,
  } = props;

  const [activeVisualId, setActiveVisualId] = useState<string | undefined>(
    undefined,
  );

  const [redirectActiveId, setRedirectActiveId] = useState<string>();
  const [expandedCategory, setExpandedCategory] = useState<string | undefined>(
    undefined,
  );

  const handleCategoryChange = (value: string | undefined) => {
    setExpandedCategory(value);
  };

  const {
    data: visuals = [],
    isLoading: isVisualLoading,
    error: visualsError,
  } = useGetVisualsQuery();

  const localVisuals = useMemo(
    () =>
      visuals
        .map((visual) => ({
          ...visual,
          image: getVisualAssetUrl(visual.id, visual.activeAssetVersion),
        }))
        .filter(
          (visual) =>
            !searchValue ||
            visual?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) >
              -1,
        )
        .filter((visual) => {
          if (expandedCategory === undefined) return true;
          if (expandedCategory === 'Uncategorized') return !visual.category;
          return visual.category === expandedCategory;
        }),
    [searchValue, visuals, expandedCategory],
  );

  const activeVisual = useMemo(
    () => localVisuals?.find(({ id }) => id === activeVisualId),
    [localVisuals, activeVisualId],
  );

  const handleAdd = () => {
    if (isListView) {
      if (selectedVisuals.length > 0) {
        selectedVisuals.forEach((i) => pasteHandler({ nodeId: i }));
      } else {
        pasteHandler({ nodeId: activeVisualId });
      }
    } else {
      pasteHandler({ nodeId: activeVisualId });
    }
    onClose();
  };

  const redirect = useAppSelector((state: IAppState) => state.redirect);

  useEffect(() => {
    if (redirect?.state?.selectedId)
      setRedirectActiveId(redirect?.state?.selectedId);
  }, [redirect]);

  useEffect(() => {
    if (localVisuals?.length > 0 && redirectActiveId) {
      setActiveVisualId(redirectActiveId);
      setRedirectActiveId(undefined);
    }
  }, [redirectActiveId, localVisuals]);

  const handleActive = (value: string) => {
    setActiveVisualId(value);
  };

  const handleSelect = (value: string) => {
    setSelectedVisuals((prev) => {
      if (prev.indexOf(value) !== -1)
        return prev.filter((item) => item !== value);
      return [...prev, value];
    });
  };

  useEffect(() => {
    if (localVisuals) {
      setActiveVisualId(localVisuals?.[0]?.id);
    }
  }, [localVisuals]);

  if (visualsError)
    return (
      <Stack
        sx={{
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <EmptyVisualElement
          searchValue={searchValue}
          isListView={isListView}
          error={visualsError}
        />
        <Actions>
          <Button
            id="AddElement-cancel_button"
            onClick={onClose}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
        </Actions>
      </Stack>
    );

  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        flexGrow: 1,
        minHeight: 0,
      }}
    >
      <ModalContentLeft
        handleActive={handleActive}
        activeVisualId={activeVisualId}
        handleSelect={handleSelect}
        isDrawerOpened={isDrawerOpened}
        isListView={isListView}
        isTablet={isTablet}
        onClose={onClose}
        pasteHandler={pasteHandler}
        searchValue={searchValue}
        selectedVisuals={selectedVisuals}
        toggleDrawer={toggleDrawer}
        visuals={localVisuals}
        handleInnerModalOpen={handleInnerModalOpen}
        handleSearchInput={handleSearchInput}
        toggleListView={toggleListView}
        expandedCategory={expandedCategory}
        handleCategoryChange={handleCategoryChange}
      />
      {activeVisual ? (
        <ModalContentRight
          key={activeVisual?.id}
          activeVisual={activeVisual}
          handleAdd={handleAdd}
          isListView={isListView}
          isVisualLoading={isVisualLoading}
          onClose={onClose}
          selectedVisuals={selectedVisuals}
          setActiveVisualId={setActiveVisualId}
        />
      ) : null}
    </Stack>
  );
};

export default ModalContent;
