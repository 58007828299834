import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@mui/material';
import { isIOS } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Resizable } from 're-resizable';
import _ from 'lodash';
import { ROUTES_PATH } from '../../constants';
import EditArea from '../../common/Diagram/CreateProjectDiagram/EditArea';
import Table from '../../components/project/Table';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import ConfigTabs from '../../components/project/create-config/ConfigTabs';
import { setRedirect } from '../../redux/modules/redirect';
import ConfigrationActions from '../../components/project/create-config/ConfigrationActions';
import RightContentBlock from '../../components/project/create-config/RightContent';
import { useAppDispatch } from '../../redux/store';
import useGetPermissions from '../../hooks/useGetPermissions';
import {
  Root,
  HeadWrap,
  Head,
  InnerWrap,
  LeftContent,
} from './commonEditorStyles';
import useYAMLTemplateEditorState from '../../hooks/useYAMLTemplateEditorState';
import LeftContentBlock from '../../components/project/visuals/LeftContent';
import { Content, SidebarEditor } from './styles';

const CreateConfigration = () => {
  const { id: pageId } = useParams<{ id: string }>();

  const theme = useTheme();
  const handleRight = useFullScreenHandle();
  const handleLeft = useFullScreenHandle();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [fullscreenRight, setFullscreenRight] = useState<boolean>(false);
  const [fullscreenLeft, setFullscreenLeft] = useState<boolean>(false);

  const [isEditor, setIsEditor] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [tabValue, setTabValue] = useState<string>('0');

  const redirect = useSelector((state: IAppState) => state.redirect);

  const { json, text: textValue } = useYAMLTemplateEditorState({ id: pageId });

  const history = useHistory();

  const { id: configIdParam } = useParams<{ id: string }>();

  const configType = useMemo(
    () => (configIdParam?.indexOf('PROJ') > -1 ? 'project' : 'template'),
    [configIdParam],
  );

  const { isActionGranted: isEditorHandlingGranted } = useGetPermissions({
    permissionsPath:
      configType === 'template'
        ? 'structure:systemConfigurationTemplates:create'
        : 'structure:projects:update',
  });

  useEffect(() => {
    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [isUpdated]);

  const dispatch = useAppDispatch();

  const handleEditorMode = () => {
    setIsEditor((prev) => !prev);
  };

  const handleExit = () => {
    if (configType === 'template') {
      dispatch(
        setRedirect({
          route: `${ROUTES_PATH.PROJECTS}/ens`,
          state: { addDialogOpened: true },
        }),
      );
    }
    if (configType === 'project') {
      dispatch(
        setRedirect({
          route: `${ROUTES_PATH.PROJECT_DETAIL}/${configIdParam}`,
          state: {},
        }),
      );
    }
  };

  const handleRemoveLocalStorageData = () => {
    const createProjectData = JSON.parse(
      sessionStorage.getItem('createProjectData'),
    );
    sessionStorage.setItem(
      'createProjectData',
      JSON.stringify({
        ...createProjectData,
        basedOn: '',
      }),
    );
  };

  useEffect(() => {
    if (redirect.route !== '') {
      const createProjectData = JSON.parse(
        sessionStorage.getItem('createProjectData'),
      );
      sessionStorage.setItem(
        'createProjectData',
        JSON.stringify({
          ...createProjectData,
          basedOn: createProjectData?.basedOn || '',
        }),
      );
      history.push(redirect.route, redirect.state);
    }
  }, [redirect]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const beforeUnLoad = () => {
      localStorage.setItem('currentCreateConfig', textValue);
    };

    if (!configIdParam) {
      window.addEventListener('beforeunload', beforeUnLoad);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [textValue, configIdParam]);

  return (
    <Root>
      <HeadWrap>
        <Head>
          <ConfigrationActions
            handleExit={handleExit}
            isUpdated={isUpdated}
            isEditable={isEditorHandlingGranted}
            isForMobile
            isForDesktop
          />
        </Head>
      </HeadWrap>
      <InnerWrap tabValue={tabValue} desktop={desktop}>
        <Resizable
          style={{
            width: '50%',
            minWidth: '200px',
          }}
          handleWrapperClass="handle"
          defaultSize={{
            width: '50%',
            height: '100%',
          }}
          enable={{ right: true }}
        >
          <SidebarEditor
            component="aside"
            sx={{
              '& .fullscreen': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <FullScreen handle={handleLeft}>
              <LeftContent className={fullscreenLeft ? 'fullscreen' : ''}>
                <LeftContentBlock
                  fullscreenLeft={fullscreenLeft}
                  handleLeft={handleLeft}
                  setFullscreenLeft={setFullscreenLeft}
                  isVisuals={false}
                  isEditor={isEditor}
                  handleEditorMode={handleEditorMode}
                />

                {isEditor ? (
                  <EditArea configID={configIdParam} />
                ) : (
                  <Table data={json} />
                )}
              </LeftContent>
            </FullScreen>
          </SidebarEditor>
        </Resizable>
        <Content
          component="section"
          sx={{
            p: 0,
            '& .fullscreen': {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <FullScreen handle={handleRight}>
            <RightContentBlock
              isIOS={isIOS}
              handleRight={handleRight}
              fullscreenRight={fullscreenRight}
              setFullscreenRight={setFullscreenRight}
              isEditable={isEditorHandlingGranted}
            />
          </FullScreen>
        </Content>
      </InnerWrap>
      {!desktop && <ConfigTabs onChange={handleTabChange} value={tabValue} />}
    </Root>
  );
};

export default CreateConfigration;
